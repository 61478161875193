export default {
	namespaced: true,
	// modules: {}
	state: {
		onWSMessageStoreMeal: null,
		/** @type {BlueTooth | null} Printer001 */
		BTPosPrinter: null,
		BTPosPrinterSecond: null,
		/** @type {BlueTooth | null} DL-XXXX */
		BTKioskPrinter: null,
		BTTagPrinter: null,
		BTPosPrinterDevice: null,
		BTPosPrinterDeviceSecond: null,
		posPrinterStatus: "OFFLINE",
		posSecondPrinterStatus: "OFFLINE",
		tagPrinterStatus: "OFFLINE",
	},
	mutations: {},
	actions: {
		setBTPosPrinter($, val) {
			$.BTPosPrinter = val
		},
		setBTPosPrinterSecond($, val) {
			$.BTPosPrinterSecond = val
		},
		setBTKioskPrinter($, val) {
			$.BTKioskPrinter = val
		},
	},
}
