var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`inline-form inline-form--${_vm.color} ${
		'inline-form--location-' + _vm.labelPosition
	}`},[(_vm.label.length > 0)?_c('div',{staticClass:"inline-form__label",class:{ 'inline-form__label--validate': _vm.validate },style:({
			width: _vm.labelWidth,
			minWidth: _vm.labelWidth,
			textAlign: _vm.labelAlign,
		})},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.requireDot)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"inline-form__input"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }