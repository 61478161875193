import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import orderApi from '../utils/service/order'
import { Buffer } from 'buffer'
import { isWebview } from '@/utils/storeId'

export default {
	namespaced: true,
	// modules: {}
	state: {
		socket: null,
		stompClient: null,
		heartbeatTimer: null, // 心跳計時器
		errorTimeout: null, // 斷線重連計時器
		errorCount: 0, // 斷線重連次數
		isConnected: false, // 斷開標示 false:不需要重連 / true:需要重連
		waitPosReceiptQueue: [], //pos 待出單資料
		waitPosSecondReceiptQueue: [], //pos 2 待出單資料
		waitKioskReceiptQueue: [], // kiosk 待出單資料
		isLockPosPrinter: false, // pos 出單鎖
		isLockPosSecondPrinter: false, // pos 2 出單鎖
		isLockKioskPrinter: false, // kiosk 出單鎖
	},
	mutations: {},
	actions: {
		initWebSocket(ctx) {
			const { dispatch, state } = ctx
			state.isConnected = true
			dispatch('connection')
			state.heartbeatTimer = setInterval(() => {
				try {
					state.stompClient.send('/app/connect')
				} catch (err) {
					$devLog('心跳異常')
					if (state.isConnected) {
						$devLog('嘗試重連')
						dispatch('reconnection')
					} else {
						$devLog('關閉心跳計時器')
						clearInterval(state.heartbeatTimer)
					}
				}
			}, 60000)
		},
		connection(ctx) {
			const { state, dispatch, rootState } = ctx
			if (!state.isConnected) {
				return
			}
			if (state.socket === null && state.stompClient === null) {
				state.socket = new SockJS(
					`${process.env.VUE_APP_BASE_API}/app/websocket`,
				)
				state.stompClient = Stomp.over(state.socket)
				state.stompClient.debug = null
			}
			state.stompClient.connect(
				{
					store: rootState.storage.storeId,
				},
				() => {
					dispatch('successCallback')
				},
				err => {
					console.error(err)
					dispatch('onErrorReConnection')
				},
			)
		},
		successCallback(ctx) {
			const { state, dispatch, commit } = ctx
			state.errorCount = 0
			$devLog('%c連接成功', 'color:green;background-color:#fff;')
			// 訂閱平台公告通道
			state.stompClient.subscribe(`/announcement/platform`, async msg => {
				const data = JSON.parse(msg.body)
				$devLog('/announcement/platform', data)
				if (data.event === 'START_MAINTENANCE') {
					ctx.commit(
						'setMaintenance',
						{
							modal: true,
							title: data.title,
							message: data.message,
						},
						{ root: true },
					)
					return
				}

				if (data.event === 'END_MAINTENANCE') {
					ctx.commit(
						'setMaintenance',
						{
							modal: false,
							title: '',
							message: '',
						},
						{ root: true },
					)
				}
			})
			// 訂閱後台通道
			state.stompClient.subscribe('/user/topic/backstage', msg => {
				const data = JSON.parse(msg.body)
				$devLog('/topic/backstage', data)
				const { rootState } = ctx
				if (rootState.storage.storeId === data.storeId) {
					if (data.action === 'resetPassword') {
						const logoutId = data.accounts.indexOf(rootState.storage.userId)
						if (logoutId !== -1) {
							this.commit('showMessage', '密碼已修改，三秒後即將登出！')
							this.dispatch('disconnect')
							setTimeout(() => this.dispatch('logout'), 3000)
						}
					} else {
						if (data.orderSource !== 'BACK' && data.insertNotice) {
							commit('onPlayNoticeAudio', true, { root: true })
						} else if (data.bookingNotice) {
							commit('onPlayBookingOrderNoticeAudio', true, { root: true })
						} else if (data.idleNotice) {
							commit('onPlayIdleOrderNoticeAudio', true, { root: true })
						}

						const pathname = window.location.pathname
						if (pathname !== null && !pathname.startsWith('/order-manage')) {
							rootState.orderManagerRedTag = true
						}
						rootState.order.onWSMessageStoreMeal = {
							...data,
							_new: true,
						}
					}
				}
			})
			// 訂閱列印通道
			state.stompClient.subscribe('/user/topic/printer', async msg => {
				// {String} machineType, {byte[]} clientReceipt, {byte[]} storeReceipt;
				const data = JSON.parse(msg.body)
				const { rootState } = ctx
				if (rootState.storage.storeId === Number(data.storeId)) {
					$devLog('/topic/printer', data)
					dispatch('printWithDataAndPlatform', data)
				}
			})
			// 訂閱公告通道
			if (ctx.rootState.storage.manager) {
				// 高權限者 不用訂閱公告
			} else {
				state.stompClient.subscribe('/user/announcement', async msg => {
					const data = JSON.parse(msg.body)
					$devLog('/announcement', data)

					this.commit(
						'storage/setAnnouncementDisplayShow',
						data.notify === 'REFRESH'
							? {
									status: `REFRESH${Math.random() * 100000}${
										Math.random() * 100000
									}`,
									id: null,
							  }
							: {
									status: `REFRESH${Math.random() * 100000}${
										Math.random() * 100000
									}`,
									id: data.id,
							  },
					)
				})
			}
			const storeId = ctx.rootState.storage.storeId
			if (storeId) {
				// 訂閱刷新通道
				state.stompClient.subscribe(`/refresh/${storeId}`, async msg => {
					const data = JSON.parse(msg.body)
					$devLog('/refresh', data)
					if (storeId !== data.storeId) {
						$devLog('店家id不合')
						return
					}
					if (data.type === 'SETTING') {
						// 更新店家設定
						ctx.commit('setRefreshSetting', true, { root: true })
					}

					if (data.type === 'MENU') {
						// 當前在pos頁才刷新
						const pathname = window.location.pathname
						if (pathname !== null && pathname.startsWith('/store-pos')) {
							ctx.commit('setRefreshMenu', true, { root: true })
						}
					}
				})
			}
		},
		onErrorReConnection(ctx) {
			const { dispatch, state, rootState } = ctx
			if (rootState.maintenance.modal) {
				state.errorCount = 1
			} else {
				state.errorCount++
			}
			state.isConnected = false
			$devLog(
				`%c斷線續連開始-第%i次`,
				'color:red;background-color:#fff;',
				state.errorCount,
			)

			if (state.errorCount < 60) {
				// 60*3000 = 3分鐘
				state.errorTimeout = setTimeout(() => dispatch('reconnection'), 3000)
			} else {
				state.errorCount = 0
				$devLog(
					`%c超過斷線自動連線上限,導向登出`,
					'color:red;background-color:#fff;',
				)
				this.dispatch('disconnect')
				this.dispatch('logout')
			}
		},
		async printWithDataAndPlatform({ state, rootState, dispatch }, data) {
			if (isWebview()) {
				window.ReactNativeWebView?.postMessage(
					JSON.stringify({
						type: 'print',
						data: data,
					}),
				)
				return
			}

			let printerLock
			let waitReceiptQueue
			let printer
			if (data.machineType === 'tag') {
				$devLog(`不支持標籤列印`)
				return
			} else if (data.machineType === 'kiosk') {
				printerLock = 'Kiosk'
				waitReceiptQueue = state.waitKioskReceiptQueue
				printer = rootState.order.BTKioskPrinter
			} else if (data.machineType === 'pos') {
				printerLock = 'Pos'
				waitReceiptQueue = state.waitPosReceiptQueue
				printer = rootState.order.BTPosPrinter
			} else {
				printerLock = 'PosSecond'
				waitReceiptQueue = state.waitPosSecondReceiptQueue
				printer = rootState.order.BTPosPrinterSecond
			}

			// 已上鎖的話,新增資料到Queue並返回
			if (state[`isLock${printerLock}Printer`]) {
				waitReceiptQueue.push(data)
				$devLog(
					`%s已上鎖 當前待列印數量:%s`,
					printerLock,
					waitReceiptQueue.length,
				)
				return
			}

			try {
				$devLog('%s開始列印 進行上鎖', printerLock, data)
				state[`isLock${printerLock}Printer`] = true
				await dispatch('print', {
					receipt: data.storeReceipt,
					blueTooth: printer,
					isBase64: false,
				})
				await dispatch('print', {
					receipt: data.kitchenReceipt,
					blueTooth: printer,
					isBase64: false,
				})
				if (data.clientReceipt) {
					await dispatch('print', {
						receipt: data.clientReceipt,
						blueTooth: printer,
						isBase64: false,
					})
				}
				if (data.invoice) {
					await dispatch('print', {
						receipt: data.invoice,
						blueTooth: printer,
						isBase64: true,
					})
				}

				// 模擬延時,阻塞當前線程
				// setTimeout(function () {
				// 	dispatch('printerUnLock', printerLock)
				// }, 3000)

				await dispatch('printerUnLock', printerLock)
			} catch (error) {
				state[`isLock${printerLock}Printer`] = false
				$devLog('%s出現異常 釋放鎖', printerLock)
				$devLog(`%c出現異常`, 'color:red;background-color:#fff;', error)
				throw error
			}
		},
		print(_, { receipt, blueTooth, isBase64 = false }) {
			if (!receipt) return

			return new Promise(async resolve => {
				if (blueTooth != null) {
					const SPLICE_NUM = isBase64 ? 128 : 48
					let unit8Arr

					if (isBase64) {
						unit8Arr = new Uint8Array(
							atob(receipt)
								.split('')
								.map(char => char.charCodeAt(0)),
						)

						for (let i = 0; i < unit8Arr.length; i += SPLICE_NUM) {
							const value = unit8Arr.slice(i, i + SPLICE_NUM)
							blueTooth.writeValue(value)
							// await blueTooth.writeValue(value) // 本地藍芽需要添加await
						}
					} else {
						unit8Arr = Buffer.from(receipt, 'base64')
						const len = Math.ceil(unit8Arr.length / SPLICE_NUM)

						for (let i = 0; i < len; i++) {
							const value = unit8Arr.slice(i * SPLICE_NUM, (i + 1) * SPLICE_NUM)
							blueTooth.writeValue(value)
							// await blueTooth.writeValue(value) // 本地藍芽需要添加await
						}
					}
				} else {
					$devLog(`%c印表機沒有連線`, 'color:red;background-color:#fff;')
				}
				resolve()
			})
		},
		printerUnLock({ state, dispatch }, name) {
			if (state[`wait${name}ReceiptQueue`].length > 0) {
				const nextData = state[`wait${name}ReceiptQueue`][0]
				$devLog(
					`${name} 待列印數量為:%s 繼續列印`,
					state[`wait${name}ReceiptQueue`].length,
					nextData,
				)
				state[`isLock${name}Printer`] = false
				state[`wait${name}ReceiptQueue`].shift()
				dispatch('printWithDataAndPlatform', nextData)
			} else {
				$devLog(`${name} 待列印數量為0 釋放鎖`)
				state[`isLock${name}Printer`] = false
			}
		},
		reconnection(ctx) {
			const { dispatch } = ctx
			dispatch('disconnect')
			dispatch('initWebSocket')
		},
		async disconnect(ctx) {
			const { state } = ctx
			$devLog(`收到斷線請求`)
			state.errorCount++
			state.isConnected = false
			if (state.stompClient !== null) {
				await state.stompClient.disconnect()
				state.socket = null
				state.stompClient = null
				clearInterval(state.heartbeatTimer)
				clearTimeout(state.errorTimeout)
			}
		},
		async updateOrderStatus(ctx, data) {
			const { id, status, thirdPartyDelivery } = data
			const { rootState } = ctx

			await orderApi.updateOrderStatus({
				orderId: id,
				storeId: rootState.storage.storeId,
				status,
				deliveryOrder: thirdPartyDelivery,
			})
		},
	},
}
