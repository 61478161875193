<template>
	<v-dialog
		:value="value"
		@input="onToggleModal"
		:max-width="maxWidth"
		:persistent="persistent"
		content-class="modal-dialog"
		:fullscreen="fullscreen"
	>
		<v-card :class="{ 'double-view': doubleView }">
			<slot name="title" v-if="!doubleView">
				<v-card-title class="headline modal-title" v-if="title !== ''">{{
					title
				}}</v-card-title>
			</slot>
			<v-card-text>
				<slot></slot>
				<slot name="actions">
					<v-card-actions
						:class="{ 'my-modal-center-actions': centerActions }"
						v-if="!doubleView"
					>
						<!--					<v-spacer></v-spacer>-->
						<v-btn
              v-if="cancelName"
							color="lineColor"
							style="font-size: 1rem"
							class="cancel-name"
							@click="onCancel"
							large
							outlined
						>
							{{ cancelName }}
						</v-btn>

						<v-btn
							v-if="centerBtnName"
							color="success"
							style="font-size: 1rem"
							@click="onCenterBtn"
							large
						>
							{{ centerBtnName }}
						</v-btn>

						<v-btn
							:disabled="submitDisabled"
							color="com1Color"
							style="font-size: 1rem"
							@click="onSubmit"
							:large="btnSize"
							:loading="submitLoading"
						>
							{{ submitName }}
						</v-btn>
					</v-card-actions>
				</slot>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'MyModal',
	// components: { COMPONENT_NAME },
	props: {
		value: {
			required: false,
			type: Boolean,
		},

		maxWidth: {
			required: false,
			type: Number,
			default: 384,
		},
		doubleView: {
			required: false,
			type: Boolean,
		},
		// title 為空將移除 title
		title: {
			required: false,
			type: String,
			default: '帥氣的抬頭',
		},
		submitLoading: {
			required: false,
			type: Boolean,
		},
		isSubmitHideModal: {
			required: false,
			type: Boolean,
		},
		cancelName: {
			required: false,
			type: String,
			default: '取消',
		},
		submitName: {
			required: false,
			type: String,
			default: '確認',
		},
		submitDisabled: {
			required: false,
			type: Boolean,
		},
		btnSize: {
			required: false,
			type: Boolean,
			default: true,
		},
		eventStop: {
			type: Boolean,
			required: false,
			default: false,
		},
		centerActions: {
			type: Boolean,
			required: false,
			default: false,
		},
		centerBtnName: {
			required: false,
			type: String,
		},
		isOnCenterBtnHideModal: {
			required: false,
			type: Boolean,
		},
		persistent: {
			type: Boolean,
			required: false,
			default: false,
		},
		fullscreen: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	model: {
		prop: 'value',
		event: 'updateModalShow',
	},
	// data() {return {}},
	// computed: {},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	methods: {
		onHideModal() {
			this.$emit('updateModalShow', false)
		},
		onToggleModal() {
			this.$emit('updateModalShow', !this.value)
		},
		onCancel(ev) {
			if (this.eventStop) ev.stopPropagation()
			this.onHideModal()
			this.$emit('onCancel')
		},
		onSubmit(ev) {
			if (this.eventStop) ev.stopPropagation()
			if (this.isSubmitHideModal) this.onHideModal()
			this.$emit('onSubmit')
		},
		onCenterBtn(ev) {
			if (this.eventStop) ev.stopPropagation()
			if (this.isOnCenterBtnHideModal) this.onHideModal()
			this.$emit('onCenterBtn')
		},
	},
}
</script>
<style lang="scss">
//.v-application {
//	touch-action: none;
//}
.modal-dialog {
	box-shadow: none;
	overflow: hidden;
	touch-action: none;
}
</style>
<style lang="scss" scoped>
.modal-dialog {
	& > .v-card {
		border-radius: 16px;
		box-shadow: none;
		> * {
			flex: 1;
		}
		& .v-card__title.modal-title {
			width: 100%;
			font-size: 1.5rem !important;
			display: flex;
			touch-action: none;
			align-items: center;
			justify-content: center;
			font-weight: bold;
			padding: 30px 32px 13px 32px;
		}
		.v-card__text {
			margin: 0;
			width: 100%;
			touch-action: none;
			height: 100%;
			padding: 9px 32px 32px 32px;
			.left-card {
				width: 100%;
			}
		}
		.v-card__actions {
			width: 100%;
			touch-action: none;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 16px;
			padding: 24px 0px 0px 0px;
			> ::v-deep .v-btn {
				flex: 1;
				height: 56px;
			}
			.cancel-name {
				color: $lightTextColor !important;
				border-color: $lineColor !important;
				border-width: 2px !important;
			}
		}
	}
	.double-view {
		height: calc(var(--vh) * 90);
		.modal-title {
			justify-content: flex-start !important;
		}
	}
}
</style>
<style lang="scss"></style>
